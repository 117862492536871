<template>
  <div>

  </div>
</template>

<script>
// 企微扫描后的回调页面，用于登录
export default {
  data() {
    return {
      code: '',
      state: ''
    }
  },
  mounted() {
    let that = this;

    let pageQuery = that.$route.query
    if (pageQuery.code && pageQuery.state) {
      that.code = pageQuery.code
      that.state = pageQuery.state
      that.getWxLoginFun(that.code, that.state)
    } else {
      that.$message.error('缺少回调参数！')
    }

  },
  methods: {
    //企微登录
    getWxLoginFun(code, state) {
      let that = this;
      that.request({
        method: 'post',
        url: '/wxCp/login',
        param: {
          code: code,
          state: state
        },
        success: function (res) {
          // console.log(res, '---wxCp/login res')
          if (res && res.code == 200) {
            if (res.result) {
              localStorage.setItem('hkmanage_session', res.result) //这个必须设置，否则router.js里一直跳转到登录

              // let willToPath = localStorage.getItem('hkmanage_path_to')
              // console.log(localStorage, '------企微 登录成功之后---', willToPath)

              that.$router.push('/')
            } else {
              console.log(res, '---没有result')
              that.$message.error("登录失败，请联系开发人员" + res.message)
            }
          } else {
            console.log(res, '---没有res 或者 not 200')
            that.$message.error("企微登录失败，" + res.message)
          }
        },
        error: function (error) {
          //服务器异常
          console.log(error)
          that.$message.error('企微登录异常。' + error);
        }
      })
    },


  }
}
</script>
<style lang="scss" scope>
</style>